import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  .no-bar-data {
    width: 100%;
    height: 100%;
    font-family: 'Prompt-Medium';
    font-size: 18px;
  }
  .recharts-legend-wrapper {
    right: 0;
    .legend-wrapper {
      position: relative;
      left: 24px;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      .each-legend-wrapper {
        margin-right: 16px;
        &:last-child {
          margin: 0;
        }
        .legend-color {
          width: 14px;
          height: 14px;
          margin-right: 9px;
        }
        .legend-datakey {
          font-size: 12px;
        }
      }
    }
  }

  .recharts-responsive-container {
    .recharts-wrapper {
      .forecast-yAxis-label {
        font-size: 12px;
      }
    }
  }

  .recharts-tooltip-wrapper {
    background: none;
    .tooltip-wrapper {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px;
      .each-tooltip-wrapper {
        .color-label {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .data-label {
          font-size: 12px;
        }
      }
      .time-label-wrapper {
        font-size: 16px;
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    /* .recharts-legend-wrapper {
      .legend-wrapper {
        .each-legend-wrapper {
          margin-right: 16px;
          &:last-child {
            margin: 0;
          }
          .legend-color {
            width: 8px;
            height: 8px;
            margin-right: 9px;
          }
          .legend-datakey {
            font-size: 12px;
          }
        }
      }
    } */
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
  }
`
