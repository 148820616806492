import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, XAxis, YAxis, BarChart, Bar, Legend, CartesianGrid, Tooltip } from 'recharts'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import ContactZoneStyled from './styled'

import { withTranslation } from '../../../i18n'

const tickStyled = {
  fontFamily: 'Prompt',
  fontSize: 12,
  fill: '#fff',
}

export class ContactZoneWrapper extends React.PureComponent {
  renderLegend = ({ payload }) => {
    let dataLegend = []
    if (payload) {
      dataLegend = payload.map(legend => {
        const backgroundColor = this.props.barColors[legend.dataKey]
        return (
          <div className="each-legend-wrapper flex align-items-center" key={`${legend.value}${legend.color}`}>
            <div className="legend-color" style={{ backgroundColor }} />
            <div className="legend-datakey">{this.props.t(legend.dataKey.toLowerCase())}</div>
          </div>
        )
      })
    }
    return <div className="legend-wrapper">{dataLegend}</div>
  }

  renderTooltip = ({ payload }) => {
    let tooltipData = []
    let label = ''
    if (payload) {
      tooltipData = payload.map(eachData => {
        label = eachData.payload.label
        return (
          <div className="each-tooltip-wrapper flex align-items-center" key={eachData.dataKey}>
            <div className="color-label" style={{ backgroundColor: eachData.color }} />
            <div className="data-label">{`${this.props.t(eachData.dataKey.toLowerCase())}: ${eachData.value}`}</div>
          </div>
        )
      })
    }
    return (
      <div className="tooltip-wrapper flex flex-column">
        <div className="time-label-wrapper">{label}</div>
        {tooltipData}
      </div>
    )
  }

  renderBars() {
    let output = null
    if (!this.checkIsNoData()) {
      const preparedListKey = Object.keys(this.props.data[0]).filter(key => key !== 'label')
      output = preparedListKey.map(dataKey => {
        return <Bar key={dataKey} barSize={16} dataKey={dataKey} stackId="zone" fill={this.props.barColors[dataKey]} legendType="none" />
      })
    }
    return output
  }

  checkIsNoData() {
    return this.props.data.length === 0
  }

  getYAxisLabel(text) {
    return (
      <text x={60} y={32} fill="#fff" textAnchor="middle" className="forecast-yAxis-label">
        {text}
      </text>
    )
  }

  renderContactZoneChart() {
    let output = null
    if (this.props.loading) {
      output = (
        <div className="no-bar-data flex justify-center align-items-center">
          <SkeletonAnimation id="contact-zone-skeleton-loading" />
        </div>
      )
    } else if (this.checkIsNoData()) {
      const noData = this.props.t('no_data')
      output = <div className="no-bar-data flex justify-center align-items-center">{noData}</div>
    } else {
      output = (
        <ResponsiveContainer width={'100%'} height={'100%'} data-test="chart-contact-zone">
          <BarChart data={this.props.data} margin={{ top: 60, left: 0, bottom: 24, right: 45 }}>
            <CartesianGrid x={60} vertical={false} style={{ opacity: 0.3, width: '100%' }} />
            <YAxis
              label={this.getYAxisLabel(this.props.t('vehicles'))}
              axisLine={false}
              type="number"
              tickLine={false}
              tick={tickStyled}
              allowDecimals={false}
            />
            <XAxis dataKey="label" interval={0} axisLine={false} tickLine={false} tick={tickStyled} tickMargin={13} />
            {this.renderBars()}
            <Legend verticalAlign="bottom" iconSize={14} content={this.renderLegend} />
            <Tooltip cursor={false} content={this.renderTooltip} />
          </BarChart>
        </ResponsiveContainer>
      )
    }
    return output
  }

  checkMessage(message, val) {
    return message === '' ? val : message
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('contact_zone')} id={'header-contact-zone'}>
        {this.renderContactZoneChart()}
      </CardWrapper>
    )
  }

  render() {
    return (
      <ContactZoneStyled
        ref={con => {
          this.container = con
        }}
        className={this.props.className}
      >
        {this.renderContent()}
      </ContactZoneStyled>
    )
  }
}

ContactZoneWrapper.defaultProps = {
  loading: false,
}

ContactZoneWrapper.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      'zone A': PropTypes.number,
      'zone B': PropTypes.number,
      'zone C': PropTypes.number,
      Unknown: PropTypes.number,
    }),
  ).isRequired,
  barColors: PropTypes.object,
  loading: PropTypes.bool,
}

export default withTranslation()(ContactZoneWrapper)
